/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React from 'react';
import { Link, graphql } from 'gatsby';
// ProtoTypes
import PropTypes from 'prop-types';

// Lib UI components
import { Container, Visible } from 'react-grid-system';

// Local UI components
import PageLayout from '../shared/PageLayout';
import Seo from '../shared/Seo';

// Details components :
import DetailStrapi from '../page-components/Details-events/detail-strapi';
import DetailJalios from '../page-components/Details-events/detail-jalios';

/* -------------------------------------------------------------------------- */
/*                                    Page                                    */
/* -------------------------------------------------------------------------- */

function DetailsEvent({ location }) {
  /* ------------------------------- HELPERS -------------------------------- */
  function EventDetailStrapi(event, Src) {
    if (Src === 'Strapi') {
      return <DetailStrapi Data={event} />;
    }
    if (Src === 'Jalios') {
      return <DetailJalios Data={event} />;
    }
  }
  /* ****************************** RENDERING ******************************* */
  return (
    <PageLayout>
      <Seo title="event" />

      <Container className="page-about fluid">
        <Container className="about-us-page fluid" fluid>
          <Container>
            <Visible lg xl xxl>
              <Container className="top flex items-center fluid">
                <Link className="breadcrumb__link" to="/">
                  Accueil
                </Link>
                <span className="m-r-l">/</span>
                <Link className="breadcrumb__link" to="/evenements">
                  Événements
                </Link>
                <span className="m-r-l">/</span>
                <span className="breadcrumb__link">Event</span>
              </Container>
            </Visible>
            {EventDetailStrapi(location?.state?.event, location?.state?.Source)}
          </Container>
        </Container>
      </Container>
    </PageLayout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["Partners", "Common"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
// Location Proptypes Requirements:
const LocationShape = {
  Source: PropTypes.string.isRequired,
  actuality: PropTypes.object.isRequired,
};

DetailsEvent.propTypes = {
  location: PropTypes.objectOf(PropTypes.shape(LocationShape)).isRequired,
};

export default DetailsEvent;
