/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */
// Packages
import React from 'react';
import { format } from 'date-fns';
import ReactMarkdown from 'react-markdown';
// PropTypes
import PropTypes from 'prop-types';
// Lib UI components
import { Container, Col } from 'react-grid-system';
import Carousel, { consts } from 'react-elastic-carousel';
import Ebook from '../../shared/UIKit/Ebook';
// Fake APIS
import { LINK_API } from '../../shared/APIs';
// Assets
import DATE_PICKER_ICON from '../../images/Events/calendar.svg';
import PDF_ICON from '../../images/Events/PDF-ICON.svg';
import PREV_ICON from '../../images/index/actualities-section/prev.png';
import NEXT_ICON from '../../images/index/actualities-section/next.png';

// Style
import '../../page-styles/details.scss';

function DetailStrapi({ Data }) {
  /* --------------------------------- CONSTS --------------------------------- */
  const FORMATED_EVENT_DATE = new Date(Data?.attributes?.date);
  const STYLED_EVENT_DATE = format(FORMATED_EVENT_DATE, 'dd/MM/yyyy');

  /* -------------------------------- Helpers ------------------------------- */
  const PDFLinks = Data?.attributes?.file?.data?.map((PDF) => (
    <div className="pdf">
      <h5>{PDF.attributes.name}</h5>
      <a href={LINK_API + PDF?.attributes?.url}>
        <img className="img-pdf" src={PDF_ICON} alt="PDF" />
      </a>
    </div>
  ));

  // eslint-disable-next-line react/prop-types
  const Arrows = ({ type, onClick, isEdge }) => {
    // eslint-disable-next-line operator-linebreak
    const pointer =
      type === consts.PREV ? (
        <img src={PREV_ICON} alt="previous_icon" />
      ) : (
        <img src={NEXT_ICON} alt="next_icon" />
      );
    return (
      <button
        type="submit"
        className="slider-button"
        onClick={onClick}
        disabled={isEdge}
      >
        {pointer}
      </button>
    );
  };

  function Images() {
    return Data?.attributes?.picture?.data?.map(({ attributes: { url } }) => (
      <img src={LINK_API + url} alt="event" className="details-img" />
    ));
  }

  /* -------------------------------- Rendering ------------------------------- */
  return (
    <Container className="details fluid">
      <Carousel
        // enableAutoPlay
        autoPlaySpeed={1000}
        enableSwipe
        enableMouseSwipe
        showArrows
        pagination={false}
        itemsToShow={1}
        renderArrow={Arrows}
      >
        {Images()}
      </Carousel>
      <h1>{Data?.attributes.title}</h1>
      <Container className="date flex fluid">
        <img src={DATE_PICKER_ICON} alt="date_picker_icon" />
        <span>{STYLED_EVENT_DATE}</span>
      </Container>
      <ReactMarkdown>{Data?.attributes.description}</ReactMarkdown>
      <Col>{PDFLinks === undefined ? PDFLinks : null}</Col>
      {Data?.attributes?.video?.data?.attributes?.url !== undefined ? (
        <div className="details-video">
          <video controls width="100%" height="100%">
            <track kind="captions" />
            <source
              src={LINK_API + Data?.attributes?.video?.data?.attributes?.url}
              type="video/mp4"
            />
          </video>
        </div>
      ) : (
        ''
      )}
      {Data?.attributes?.urlVideo === null ||
      Data?.attributes?.urlVideo === '' ? (
        ''
      ) : (
        <div className="details-video">
          <iframe
            width="100%"
            height="100%"
            title={Data?.attributes?.title}
            src={
              Data?.attributes?.urlVideo.indexOf('watch?v=') !== -1
                ? `https://www.youtube.com/embed/${
                    Data?.attributes?.urlVideo.split('v=')[1]
                  }`
                : `https://www.youtube.com/embed/${
                    Data?.attributes?.urlVideo.split('/')[3]
                  }`
            }
          />
        </div>
      )}
      {Data?.attributes?.ebook?.url && (
        <Ebook
          url={Data?.attributes?.ebook?.url}
          title={Data?.attributes?.ebook?.title}
        />
      )}
    </Container>
  );
}

export default DetailStrapi;
const contentShape = PropTypes.object.isRequired;
DetailStrapi.propTypes = {
  Data: PropTypes.objectOf(PropTypes.shape(contentShape)).isRequired,
};
