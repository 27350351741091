/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react/no-danger */
/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */
// Packages
import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import axios from 'axios';
import { Link } from 'gatsby';
// Lib UI components
import { Container } from 'react-grid-system';
// PropTypes
import PropTypes from 'prop-types';
// HELPERS API END POINT
import { EVENT_JALIOS_IMAGE_SOURCE } from '../../shared/APIs';
// Assets
import DATE_PICKER_ICON from '../../images/Events/calendar.svg';
// Style
import '../../page-styles/details.scss';

function DetailStrapi({ Data }) {
  /* --------------------------------- CONSTS --------------------------------- */
  if (Data === undefined) {
    return null;
  }
  /* ---------------------------------- HOOKS --------------------------------- */
  // state
  const [imageSource, setImageSource] = useState();
  async function getImageAPI() {
    if (Object.hasOwn(Data, 'documentsDBID')) {
      try {
        const { data } = await axios(
          EVENT_JALIOS_IMAGE_SOURCE(Data.documentsDBID[0]),
          {
            headers: {
              Accept: 'application/json',
            },
          },
        );
        setImageSource(data);
      } catch (error) {
        setImageSource();
      }
    }
    setImageSource(undefined);
  }
  // Effect
  useEffect(() => {
    getImageAPI();
  }, []);
  const FORMATED_EVENT_DATE = new Date(Data?.startDate);
  const STYLED_EVENT_DATE = format(FORMATED_EVENT_DATE, 'dd/MM/yyyy');

  /* -------------------------------- Rendreing ------------------------------- */
  return (
    <Container className="details fluid">
      {imageSource === undefined ? (
        <img
          src="https://admin522.horizon-europe.tn/uploads/img_e354b67c1f.jpg"
          alt=""
        />
      ) : (
        <img
          src={`https://ugpo.horizon-europe.tn/${imageSource?.filename}`}
          alt=""
        />
      )}
      <h1>{Data?.title}</h1>
      <Container className="date flex fluid">
        <img src={DATE_PICKER_ICON} alt="date_picker_icon" />
        <span>{STYLED_EVENT_DATE}</span>
      </Container>
      <p dangerouslySetInnerHTML={{ __html: Data?.description }} />
      <p className="link-event">
        En svoir plus --->
        <Link
          to={`https://ugpo.horizon-europe.tn/jcms//${Data?.id}`}
          className="details-link"
        >
          lien
        </Link>
      </p>
    </Container>
  );
}

export default DetailStrapi;
const contentShape = PropTypes.object.isRequired;

DetailStrapi.propTypes = {
  Data: PropTypes.objectOf(PropTypes.shape(contentShape)).isRequired,
};
