/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React from 'react';
// ProtoTypes
import PropTypes from 'prop-types';

// Lib UI components

// Local UI components

import './index.scss';

/* -------------------------------------------------------------------------- */
/*                                    Page                                    */
/* -------------------------------------------------------------------------- */

function Ebook({ url, title = '' }) {
  return (
    <iframe
      title={title ?? 'title'}
      src={url}
      width="100%"
      height="480"
      seamless="seamless"
      allowFullScreen
      allow="autoplay; clipboard-read; clipboard-write"
      className="e-book"
    />
  );
}

Ebook.propTypes = {
  url: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default Ebook;
